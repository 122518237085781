$colour-base: #7fb8da
$colour-dark: #29688e
$colour-light: #a3d1ed
$colour-medium: #7191a4

$colour-eye-catching: $colour-dark

@import "_sidemenu"

html,
body
    height: 100%
    width: 100%

.logo
    text-align: center
    margin-top: 100px

.header
    color: #fff
    display: table
    height: 100%
    width: 100%
    position: relative
    background: url(../img/hh-lg.jpg) no-repeat center center fixed 
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover

.header h1
    margin-top: 75px
    font-size: 3.0em
    font-weight: 700

@media (min-width: 768px)
    .header h1
        margin-top: 055px
        font-size: 4.5em

.header-text
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1)

.services
    background: $colour-base
    padding: 50px 0
    color: #fff
    a
        color: $colour-eye-catching
        font-weight: 400

.service-item
    margin-bottom: 15px
    a
        color: #fff

i.service-icon
    border: 3px solid #ffffff
    border-radius: 50%
    display: inline-block
    font-size: 56px
    width: 140px
    height: 140px
    line-height: 136px
    vertical-align: middle 
    text-align: center

    &:hover
        font-size: 68px

.testimonials
    background: $colour-base
    padding: 40px 0
    color: #fff

    #testimonialList p
        font-size: 24px
        font-weight: 300
        line-height: 1.4
        display: none

        &.showTestimonial
            display: block

.about
    background: $colour-dark
    padding: 50px 0
    color: #fff
    a
        color: $colour-light
        &:hover
            color: $colour-medium
            text-decoration: none

.portfolio
    padding: 50px 0

.contact
    color: #fff
    background: $colour-dark
    padding: 15px 0 20px

    h1
        font-size: 2.3em
        font-weight: 300
        @media (min-width: 768px)
            font-size: 3.0em
    .tight
        margin-top: -5px
    h3
        font-weight: 300
        font-size: 1.6em
        a 
          letter-spacing: 1px
    a
        color: #ECD210
        &:hover
            color: $colour-light
            text-decoration: none
    ul a
        color: #fff
        &:hover
            text-decoration: none

.footer-bar
    background: #393939
    color: #777
    padding: 30px 0 10px
    .links
        text-transform: uppercase
        .wider
            padding: 0 14px 0 14px
        a
            text-decoration: none

.team
    a
        color: inherit
        text-decoration: none
        &:hover
            color: $colour-base

.block-div
    height: 50px

.sitepage
    display: table
    width: 100%
    background: url(../img/hh-sm.jpg) no-repeat right top fixed
    background-size: auto 125px

    .logo
        margin-top: 8px
        height: 100px
        margin-bottom: 17px
    .page
        background: #fff
        font-size: 125%
        color: #444
        .content
            padding: 50px 65px
            h1, h2, h3, h4
                font-weight: 300

#accordion .panel-default > .panel-heading
    color: #fff
    background: rgb(41,104,142)
    background: -moz-linear-gradient(top,  rgb(41,104,142) 0%, rgb(110,189,234) 100%)
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(41,104,142)), color-stop(100%,rgb(110,189,234)))
    background: -webkit-linear-gradient(top,  rgb(41,104,142) 0%,rgb(110,189,234) 100%)
    background: -o-linear-gradient(top,  rgb(41,104,142) 0%,rgb(110,189,234) 100%)
    background: -ms-linear-gradient(top,  rgb(41,104,142) 0%,rgb(110,189,234) 100%)
    background: linear-gradient(to bottom,  rgb(41,104,142) 0%,rgb(110,189,234) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29688e', endColorstr='#6ebdea',GradientType=0 )

a.panel-title:hover
    text-decoration: none

span.training-icon
    border: 2px solid #fff
    border-radius: 50%
    color: #fff
    display: inline-block
    font-size: 30px
    width: 55px
    height: 55px
    line-height: 50px
    text-align: center

.panel-title h4
    margin-top: 2px
    margin-bottom: 2px

.agile-tips
    background: -moz-linear-gradient(center top , #FFF, #EEEEEE) repeat scroll 0 0 transparent
    background: -webkit-gradient(linear, center top, center bottom, from(#FFF), to(#EEEEEE))
    -webkit-border-radius: 8px
    -moz-border-radius: 8px
    border-radius: 8px
    float: left
    height: 225px
    width: 100%
    border: 1px solid #ccc
    margin-bottom: 15px
    padding: 5px 10px 10px 10px
    &:hover
        -moz-box-shadow:    inset 0 0 10px #000000
        -webkit-box-shadow: inset 0 0 10px #000000
        box-shadow:         inset 0 0 10px #000000
    div
        height: 172px
        overflow: hidden
    p
        padding: 5px 0
        font-size: 85%

.cryptedmail:after
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld)

.post-excerpt
    .post-title
        font-size: 30px
        margin-bottom: 5px
    a
        text-decoration: none

.post-meta
    font-size: 80%
    color: #888

.img-left
    float: left
    padding: 0 15px 15px 0

.img-right
    float: right
    padding: 0 0 15px 15px

.new-block
  clear: both

.client.alu
    background: url('../img/clients.png') no-repeat 0 -1155px
    width: 200px
    height: 104px

.client.cassidian
    background: url('../img/clients.png') no-repeat 0 -210px
    width: 200px
    height: 104px

.client.cmhc
    background: url('../img/clients.png') no-repeat 0 0
    width: 200px
    height: 104px

.client.capitalone
    background: url('../img/clients.png') no-repeat 0 -735px
    width: 200px
    height: 104px

.client.dfo
    background: url('../img/clients.png') no-repeat 0 -840px
    width: 200px
    height: 104px

.client.edc
    background: url('../img/clients.png') no-repeat 0 -315px
    width: 200px
    height: 104px

.client.facebook
    background: url('../img/clients.png') no-repeat 0 -1050px
    width: 200px
    height: 104px

.client.fannie
    background: url('../img/clients.png') no-repeat 0 -945px
    width: 200px
    height: 104px

.client.hbo
    background: url('../img/clients.png') no-repeat 0 -630px
    width: 200px
    height: 104px

.client.nav
    background: url('../img/clients.png') no-repeat 0 -105px
    width: 200px
    height: 104px

.client.signiant
    background: url('../img/clients.png') no-repeat 0 -420px
    width: 200px
    height: 104px

.client.trend
    background: url('../img/clients.png') no-repeat 0 -525px
    width: 200px
    height: 104px


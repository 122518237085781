#sidebar-wrapper
  margin-right: -250px
  right: 0
  width: 250px
  background: #000
  position: fixed
  height: 100%
  overflow-y: auto
  z-index: 1000
  -webkit-transition: all 0.4s ease 0s
  -moz-transition: all 0.4s ease 0s
  -ms-transition: all 0.4s ease 0s
  -o-transition: all 0.4s ease 0s
  transition: all 0.4s ease 0s
  &.active
    right: 250px
    width: 250px
    -webkit-transition: all 0.4s ease 0s
    -moz-transition: all 0.4s ease 0s
    -ms-transition: all 0.4s ease 0s
    -o-transition: all 0.4s ease 0s
    transition: all 0.4s ease 0s

.sidebar-nav
  position: absolute
  top: 0
  width: 250px
  list-style: none
  margin: 0
  padding: 0
  li
    line-height: 40px
    text-indent: 20px
    a
      color: #999999
      display: block
      text-decoration: none
      &:hover
        color: #fff
        background: rgba(255,255,255,0.2)
        text-decoration: none

  & > .sidebar-brand
    height: 55px
    line-height: 55px
    font-size: 18px
    a
      color: #999999
      &:hover
        color: #fff
        background: none

.sidebar-nav li a:active,
.sidebar-nav li a:focus
  text-decoration: none

#menu-toggle
  top: 0
  right: 0
  position: fixed
  z-index: 1
.toggle
  margin: 5px 5px 0 0
